import { useEffect, useState } from 'react';
import { equalJson } from '@/common/util/objects/equalJson';
import { usePrevious } from './usePrevious';

export function useJsonEqualValue<T>(inputValue: T): T {
    const [value, setValue] = useState<T>(inputValue);
    const previous = usePrevious(value);

    useEffect(() => {
        let sameValue = false;

        // Only update the underlying value if it's actually different
        try {
            sameValue = equalJson(previous, inputValue);
        } catch (error) {
            // If there are errors with JSON parsing, fall back on considering the values as different
            sameValue = false;
            console.warn('Error comparing JSON values: ', error);
        }

        if (sameValue) {
            return;
        }

        setValue(inputValue);
    }, [inputValue, previous]);

    return value;
}

export function useJsonEqualState<T>(initialValue?: T): [T, (value: T) => void] {
    // Track changes in the input
    const [value, setValue] = useState<T>(initialValue);

    // Ensure that the output remains stable
    const outputValue = useJsonEqualValue<T>(value);

    return [outputValue, setValue];
}
