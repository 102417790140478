import { config } from '@/common/util/environment';
import { SnippetOptions } from '@fullstory/browser';

const orgIds: Record<string, string> = {
    Production: 'SZ9NP',
    QA: 'QCMR5',
};

const fullStoryOptions: Record<string, SnippetOptions> = {
    Production: {
        orgId: orgIds.Production,
    },
    QA: {
        orgId: orgIds.QA,
    },
    Dev: {
        orgId: orgIds.QA,
        devMode: true,
    },
    Local: {
        orgId: orgIds.QA,
        // devMode: true,
        debug: true,
    },
};

export function getFullStoryOptions(): SnippetOptions {
    if (config.isProd) {
        return fullStoryOptions['Production'];
    }

    if (config.isQA) {
        return fullStoryOptions['QA'];
    }

    if (config.isDev) {
        return fullStoryOptions['Dev'];
    }

    if (process.env.NODE_ENV === 'development') {
        return fullStoryOptions['Local'];
    }

    return undefined;
}
