'use client';

import React from 'react';
import styles from './page.module.scss';
import { BriefModeDetails } from '@/components/Mode/BriefModeDetails';
import { BriefCountDetails } from '@/components/counts/summary/BriefCountDetails';
import { OutageCountBar } from '@/components/counts/summary/OutageCountBar';

export default function MenuSummary(): React.ReactNode {
    return (
        <section className={styles.main}>
            <table>
                <tbody>
                    <tr>
                        <th>Mode</th>
                        <td>
                            <BriefModeDetails />
                        </td>
                    </tr>
                    <tr>
                        <th>Customers Out</th>
                        <td>
                            <BriefCountDetails />
                        </td>
                    </tr>
                </tbody>
            </table>
            <OutageCountBar />
        </section>
    );
}
