import { stringifyJSON } from './stringifyJSON';

export function equalJson(valueA: unknown, valueB: unknown): boolean {
    // Shortcut and return true if the values are strictly equal
    if (valueA === valueB) {
        return true;
    }

    return stringifyJSON(valueA) === stringifyJSON(valueB);
}
