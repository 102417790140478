import { useRoles } from './useRoles';
import { AppAction, isAllowed } from './Roles';

// TODO: need to better account for failures to retrieve authentication
export function useIsAllowed(action: AppAction): boolean {
    const roles = useRoles();

    if (roles === undefined) {
        return undefined;
    }

    const allowed = isAllowed(action, roles);
    return allowed;
}
