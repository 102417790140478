'use client';
import { useState } from 'react';
import { CountSummary, CountType } from '@/common/interfaces/containers/config/Count';
import { formatCount } from '../formatCount';
import AuthenticatedJsonContent from '@/app/(authenticated)/content/jsonGet/template';
import { useCountSummaryURL } from './getCountSummaryURL';

export function BriefCountDetails(): React.ReactNode {
    const [countSummary, setCountSummary] = useState<CountSummary>();
    const counts = countSummary?.counts || {};

    const url = useCountSummaryURL();

    let content = <>&nbsp;</>;

    const outageCount = counts[CountType.DisplayOutages];
    if (outageCount !== undefined) {
        content = <>{formatCount(outageCount)}</>;
    }

    return (
        <AuthenticatedJsonContent url={url} setData={setCountSummary}>
            {content}
        </AuthenticatedJsonContent>
    );
}
