'use client';

import dynamic from 'next/dynamic';
import { PropsWithChildren } from 'react';
import { Spinner } from './Spinner/Spinner';

const NoSsr = (props: PropsWithChildren) => {
    // TODO: Should we wrap with suspense so that things like query parameters are ready?
    return <>{props.children}</>;
};

export default dynamic(async () => Promise.resolve(NoSsr), {
    ssr: false,
    loading: () => <Spinner />,
});
